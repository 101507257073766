import { Grid } from '@chakra-ui/core'
import { rem } from 'design'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import { TEST_ID } from '~/constants/cypress'
import { humanizeBoxFrequency } from '~/utils/humanize'
import type * as Types from './PanelChangeFrequency.types'
import StopMySubscriptionButton from '~/components/CancelFlowPanels/StopMySubscriptionButton/StopMySubscriptionButton'

function PanelChangeFrequencyUI({
  panel,
  interval,
  frequencyOptions,
}: Types.PanelChangeFrequencyUIProps) {
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>
      <Body mb={rem(16)}>
        {`You receive boxes ${humanizeBoxFrequency(
          interval
        ).toLowerCase()} but you can update that to create a
          delivery schedule that fits your budget and lifestyle!`}
      </Body>
      <Grid gap={rem(16)} gridTemplateColumns="1 fr">
        {frequencyOptions.map(({ text, onClick }, idx) => (
          <ModalItemSmall
            data-cy={TEST_ID.CANCEL_FLOW_OPTION}
            key={idx}
            onClick={onClick}
          >
            {text}
          </ModalItemSmall>
        ))}
        <StopMySubscriptionButton />
      </Grid>
    </PanelLayout>
  )
}

export default PanelChangeFrequencyUI
