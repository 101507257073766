import { trackError } from '~/analytics/errors'
import {
  BoxItemCategory,
  BoxSize,
  Order,
  SubscriptionInterval,
} from '~/bb-api/schemata'

type MappingBoxItemCategory = BoxItemCategory | 'unavailable'

const humanizedBoxFrequencyMappings = {
  every_14_days: 'Every 2 weeks',
  every_15_days: 'Every 2 weeks',
  every_month: 'Every 4 weeks',
  every_42_days: 'Every 6 weeks',
  every_2_months: 'Every 8 weeks',
  every_3_months: 'Every 12 weeks',
  every_4_months: 'Every 16 weeks',
} as { [key in SubscriptionInterval]: string }

const humanizedBoxSizeMappings = {
  big: 'Big Box',
  classic: 'Classic Box',
} as { [key in BoxSize]: string }

const humanizedProductCategoryMappings = {
  beef: 'Beef',
  chicken: 'Chicken',
  pork: 'Pork',
  seafood: 'Seafood',
  miscellaneous: 'More',
  popular: 'Popular cuts',
  unavailable: 'Unavailable',
} as { [key in MappingBoxItemCategory]: string }

const humanizedProductCategoryNavItemMappings = {
  ...humanizedProductCategoryMappings,
  popular: 'Popular',
} as { [key in MappingBoxItemCategory]: string }

const humanizedProductCategoryDescriptionMappings = {
  beef: '100% Grass-Fed & Grass-Finished',
  chicken: 'Free-Range, Organic',
  pork: 'Humanely Raised',
  seafood: 'Wild-Caught',
  miscellaneous: 'Member favorites',
  popular: '',
  unavailable: 'Please select replacements',
} as { [key in MappingBoxItemCategory]: string }

const humanizedTrackingStatusMappings = {
  delivered: 'Delivered',
  in_transit: 'In Transit',
  processing: 'Processing',
} as { [key in Exclude<Order['trackingInformation']['status'], null>]: string }

const humanizedOrderTypeMappings = {
  one_time: 'One-Time Box',
  recurring: 'Recurring Subscription Box',
} as { [key in Order['type']]: string }

export const _humanize = (mapping: Object, mappingType: string) => (
  key: string | null
) => {
  const humanized = key ? mapping[key] : null

  if (key && !humanized && humanized !== '') {
    trackError((scope) => {
      scope.capture(
        new Error(`[humanize.ts] No ${mappingType} mapping found for ${key}.`)
      )
    })
  }

  return humanized
}

export const humanizeBoxFrequency: (
  interval: SubscriptionInterval
) => string = _humanize(humanizedBoxFrequencyMappings, 'box frequency')

export const humanizeBoxSize: (size: BoxSize) => string = _humanize(
  humanizedBoxSizeMappings,
  'box size'
)

export const humanizeProductCategoryTitle: (
  category: MappingBoxItemCategory
) => string = _humanize(humanizedProductCategoryMappings, 'box category')

export const humanizeProductCategoryNavItem: (
  category: MappingBoxItemCategory
) => string = _humanize(humanizedProductCategoryNavItemMappings, 'box category')

export const humanizeProductCategoryDescription: (
  category: MappingBoxItemCategory
) => string = _humanize(
  humanizedProductCategoryDescriptionMappings,
  'box category description'
)

export const humanizeTrackingStatus: (
  status: Order['trackingInformation']['status']
) => string = _humanize(humanizedTrackingStatusMappings, 'tracking status')

export const humanizeOrderType: (type: Order['type']) => string = _humanize(
  humanizedOrderTypeMappings,
  'order type'
)
