import { SubscriptionInterval } from '~/bb-api/schemata'

// Frequencies no longer supported but ones that members may still have
export const supportedFrequencies: SubscriptionInterval[] = [
  'every_14_days',
  'every_month',
  'every_42_days',
  'every_2_months',
]

export function frequencyIsSupported(frequency: SubscriptionInterval): boolean {
  return supportedFrequencies.includes(frequency)
}
