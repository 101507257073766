import { useNavigate } from '@reach/router'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import PanelLayout from '~/components/CancelFlowModals/PanelCancelLayout'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import { SubscriptionContext } from '~/context/subscription'
import { mutateSubscription } from '~/hooks/mutateSubscription'
import { LoadingPanel } from '../../LoadingPanel/LoadingPanel'
import PanelChangeFrequencyUI from './PanelChangeFrequency.ui'
import type * as Types from './PanelChangeFrequency.types'
import useFrequencyOptions from './hooks/useFrequencyOptions'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

const PanelChangeFrequency: React.FC<CancelPanelProps> = ({
  handleModalClose,
  panel,
}) => {
  const navigate = useNavigate()
  const { subscription } = React.useContext(SubscriptionContext)
  const { interval } = subscription
  const showToast = React.useContext(ToastContext)
  const [updateSubscription, { status }] = mutateSubscription('cancel_flow')
  const [
    checkingForRedirects,
    setCheckingForRedirects,
  ] = React.useState<boolean>(true)
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext)

  // Determine the Frequency to Show based on the provided panel
  let frequenciesToShow: Types.FrequenciesToShow

  switch (panel.cancelSaveComponent) {
    case 'Change Frequency - Longer than Current':
      frequenciesToShow = 'longerThanCurrentFrequency'
      break
    case 'Change Frequency - Shorter than Current':
      frequenciesToShow = 'shorterThanCurrentFrequency'
      break
    default:
      frequenciesToShow = 'allExceptCurrentFrequency'
  }

  // onUpdate Handler
  const updateInterval = React.useCallback(
    (interval) => {
      updateSubscription(
        {
          ...subscription,
          interval,
        },
        {
          onSuccess: () => {
            showToast('success', {
              children: 'You have updated your box frequency.',
            })
            trackCancellationSave(
              CANCEL_SAVE_ACTIONS.FREQUENCY_CHANGE,
              getHistoryEntry(1).pathname,
              getHistoryEntry(2).state.text
            )
          },
          onError: () => {
            showToast('error', {
              children:
                'There was an error updating your box frequency. Please try again.',
            })
          },
          onSettled: () => {
            handleModalClose()
          },
        }
      )
    },
    [
      updateSubscription,
      subscription,
      showToast,
      getHistoryEntry,
      handleModalClose,
    ]
  )

  // Generate the frequency options based on the interval and frequencies to show
  const frequencyOptions = useFrequencyOptions(
    interval,
    frequenciesToShow,
    updateInterval
  )

  // Redirect Member if looking for a shorter frequency
  // but already on the shortest frequency option
  React.useEffect(() => {
    if (
      frequenciesToShow === 'shorterThanCurrentFrequency' &&
      interval === 'every_14_days'
    ) {
      navigate('/increase-size', { replace: true })
      return
    }
    setCheckingForRedirects(false)
  }, [frequenciesToShow, interval, navigate])

  // Render a blank panel while we wait for redirect
  if (checkingForRedirects) return <PanelLayout />

  return status !== 'loading' ? (
    <PanelChangeFrequencyUI
      frequencyOptions={frequencyOptions}
      handleModalClose={handleModalClose}
      interval={interval}
      panel={panel}
    />
  ) : (
    <LoadingPanel />
  )
}

export default PanelChangeFrequency
