import React from 'react'
import { SubscriptionInterval } from '~/bb-api/schemata'
import { frequencyIsSupported } from '~/utils/frequency'
import { humanizeBoxFrequency } from '~/utils/humanize'
import type * as Types from '../PanelChangeFrequency.types'

const numberedBoxFrequencyMappings = {
  every_14_days: 2,
  every_15_days: 2,
  every_month: 4,
  every_42_days: 6,
  every_2_months: 8,
  every_3_months: 12,
  every_4_months: 16,
} as { [key in SubscriptionInterval]: number }

export default function useFrequencyOptions(
  interval: SubscriptionInterval,
  frequenciesToShow: Types.FrequenciesToShow,
  onClickHandler: Function
) {
  return React.useMemo(() => {
    const currentIntervalWeeks = numberedBoxFrequencyMappings[interval]
    const validIntervals = Object.keys(numberedBoxFrequencyMappings)
      .filter(isSubscriptionInterval)
      .filter((key: SubscriptionInterval) => {
        const frequencyInWeeks = numberedBoxFrequencyMappings[key]
        const matchesCurrentFrequency =
          frequencyInWeeks === currentIntervalWeeks

        let frequencyComparator: boolean = true
        switch (frequenciesToShow) {
          case 'longerThanCurrentFrequency':
            frequencyComparator = frequencyInWeeks > currentIntervalWeeks
            break
          case 'shorterThanCurrentFrequency':
            frequencyComparator = frequencyInWeeks < currentIntervalWeeks
        }

        // Filter out the same frequency, frequencies longer or shorter than current interval based on user situation,
        // and frequencies not currently offered/supported
        return (
          !matchesCurrentFrequency &&
          frequencyComparator &&
          frequencyIsSupported(key)
        )
      })

    const options: Types.PanelChangeFrequencyOption[] = validIntervals.map(
      (newInterval) => {
        const humanizedFrequency = humanizeBoxFrequency(
          newInterval
        ).toLowerCase()

        return {
          text: `Change frequency to ${humanizedFrequency}`,
          onClick: () => onClickHandler(newInterval),
        }
      }
    )

    return options
  }, [frequenciesToShow, interval, onClickHandler])
}

function isSubscriptionInterval(
  interval: string
): interval is SubscriptionInterval {
  return interval in numberedBoxFrequencyMappings
}
